import React from 'react';
import { Layout, BackTop } from 'antd';
import { Outlet } from 'react-router-dom';
const { Content } = Layout;

const Main = () => {
    return (
        <Layout>
            <BackTop />
            <Content
                className='site-layout-background'
                style={{
                    margin: 0,
                    minHeight: '100vh',
                }}
            >
                <Outlet />
            </Content>
        </Layout>
    );
};

export default Main;
