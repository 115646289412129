import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Typography, message, Row, Col, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import LoginBg from '../../assets/LoginBg.jpg';
import LoginLogo from '../../assets/LoginLogo.png';
import style from './Login.module.scss';
import 'antd/dist/antd.css';
import ForgetPassword from './ForgetPassword';
const { Title } = Typography;

const LoginPage = () => {
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();
    const [loginError, setLoginError] = useState('');
    const [forgetPW, setForgetPW] = useState(false);
    const navigate = useNavigate();

    const LoginFetch = async () => {
        try {
            setloading(true);
            const account = form.getFieldsValue(['username']);
            const password = form.getFieldsValue(['password']);
            const res = await fetch(process.env.REACT_APP_API_KEY + '/aw-api/user/login', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    account: account.username,
                    password: password.password,
                }),
            });
            const response = await res.json();
            if (response.status === 200) {
                message.success('登錄成功!');
                localStorage.setItem('userId', response.data.jwtToken);
                document.cookie = `userId=${response.data.jwtToken}`;
                setTimeout(() => {
                    navigate('/Home');
                }, 2000);
            } else {
                setloading(false);
                setLoginError(true);
            }
        } catch (error) {
            if (error) {
                setloading(false);
                message.error('登錄失敗 ' + error);
            }
        }
    };

    return (
        <Row justify='space-around' align='middle' style={{ backgroundColor: 'white' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={11} className={style.inputForm}>
                {!forgetPW ? (
                    <Form form={form} onFinish={LoginFetch} autoComplete='off'>
                        <div className={style.box}>
                            <Form.Item className={style.header}>
                                <img className={style.logo} src={LoginLogo} alt='LoginLogo'></img>
                                <Title level={3} className={style.companyName}>
                                    Artifact
                                </Title>
                                <Title level={2} className={style.webName}>
                                    瓦斯行管理平台
                                </Title>
                            </Form.Item>
                            <div className={style.input}>
                                <Form.Item
                                    name='username'
                                    className={style.inputBox}
                                    rules={[{ required: true, message: '請輸入帳號 或 Email' }]}
                                >
                                    <Input
                                        size='large'
                                        placeholder='帳號 或 Email'
                                        prefix={<UserOutlined className={style.icon} />}
                                    />
                                </Form.Item>
                            </div>
                            <div className={style.input}>
                                <Form.Item
                                    name='password'
                                    className={style.inputBox}
                                    rules={[{ required: true, message: '請輸入密碼' }]}
                                >
                                    <Input.Password
                                        size='large'
                                        placeholder='密碼'
                                        prefix={<LockOutlined className={style.icon} />}
                                    />
                                </Form.Item>
                            </div>
                            {loginError ? (
                                <div className={style.input}>
                                    <Form.Item className={style.inputBox}>
                                        <Alert message='帳號或密碼輸入錯誤' type='error' showIcon />
                                    </Form.Item>
                                </div>
                            ) : null}
                            <div className={style.input}>
                                <Form.Item className={style.inputBox}>
                                    <Form.Item name='remember' valuePropName='checked' noStyle>
                                        <Checkbox style={{ color: '#1890ff', fontWeight: 500 }}>
                                            記得我
                                        </Checkbox>
                                    </Form.Item>
                                    <a className={style.forgetPs} onClick={() => setForgetPW(true)}>
                                        忘記密碼
                                    </a>
                                </Form.Item>
                            </div>
                            {/* <Form.Item className={style.submit} wrapperCol={{ span: 13 }}>
								<a style={{ fontWeight: 500 }} href="">
									立即註冊
								</a>
							</Form.Item> */}

                            <Form.Item className={style.submit}>
                                <Button
                                    className={style.submitSize}
                                    size='large'
                                    type='primary'
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    登入
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                ) : (
                    <ForgetPassword />
                )}
            </Col>
            <Col xs={0} sm={0} md={0} lg={24} xl={13}>
                <img className={style.photo} src={LoginBg} alt='LoginBg'></img>
            </Col>
        </Row>
    );
};

export default LoginPage;
