import { Spin } from 'antd';
import React from 'react';
import style from './Loading.module.scss';

const Loading = () => (
    <div className={style.middle}>
        <Spin />
    </div>
);
export default Loading;
