import { PhoneTwoTone } from '@ant-design/icons';
import {
    Button,
    Space,
    Descriptions,
    Spin,
    notification,
    Typography,
    List,
    Table,
    Tabs,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { CusBillColumns } from '../../../pages/Customer/CustomerDetailed/CusBillOfSale/CusBillColumns';
import { useMemo } from 'react';
import { ToMonetFormat } from '../../../function/ToMoneyFormat';
const { Text, Paragraph } = Typography;

export const PopUpWindow = (props) => {
    const navigate = useNavigate();
    const key = 'PopUpWindow';
    const payRecord = props.phoneCusData?.payRecord || [];
    const phoneCus = props.phoneCusData?.phoneCus || {};
    useMemo(() => {
        const btn = phoneCus.customerInfos && (
            <Space>
                <Button
                    onClick={() => {
                        navigate(
                            `${'/customer/CustomerList'}/CustomerDetailed/${
                                phoneCus.customerInfos.cis_id
                            }?tab=Backorder`,
                        );
                    }}
                >
                    付款訂單列表
                </Button>
                <Button
                    type='primary'
                    ghost
                    onClick={() => {
                        navigate('/orders/NewOrders', {
                            state: { id: phoneCus.customerInfos.cis_id },
                        });
                    }}
                >
                    新增訂單
                </Button>
                <Button
                    type='primary'
                    onClick={() =>
                        navigate(
                            `${'/customer/CustomerList'}/CustomerDetailed/${
                                phoneCus.customerInfos.cis_id
                            }?tab=information`,
                        )
                    }
                >
                    查看詳細
                </Button>
            </Space>
        );

        notification.info({
            style: {
                width: 700,
                marginLeft: 310,
                display: !props.status && 'none',
                fontSize: 16,
            },
            icon: <PhoneTwoTone />,
            key,
            btn,
            duration: null,
            onClose: () => props.setOpen(),
            message: phoneCus.customerInfos ? (
                <Paragraph strong style={{ fontSize: 17 }} key={1}>
                    來自「 {phoneCus.customerInfos.customer_info.customer_name} 」的來電
                </Paragraph>
            ) : (
                `有新的來電`
            ),
            description: !phoneCus.customerInfos ? (
                phoneCus.phone_number ? (
                    <Descriptions column={1} style={{ marginTop: 20 }} key={2}>
                        <Descriptions.Item label='來電號碼'>
                            {phoneCus.phone_number}
                        </Descriptions.Item>
                    </Descriptions>
                ) : (
                    <div key={3}>
                        <Text>資料加載中... </Text>
                        <Spin size='small' />
                    </div>
                )
            ) : (
                <>
                    <Descriptions column={2} style={{ marginTop: 20 }} key={4}>
                        <Descriptions.Item label='來電號碼'>
                            {phoneCus.phone_number}
                        </Descriptions.Item>
                        <Descriptions.Item label='主要電話'>
                            {phoneCus.customerInfos.customer_info.main_phone}
                        </Descriptions.Item>
                        <Descriptions.Item label='統一編號'>
                            {phoneCus.customerInfos.customer_info.tax_id_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label='欠款金額'>
                            {ToMonetFormat(phoneCus.sumArrearsResult[0].arrears)}
                        </Descriptions.Item>
                        <Descriptions.Item label='主要地址' span={3}>
                            {phoneCus.addressList[0].address}
                        </Descriptions.Item>
                        <Descriptions.Item label='瓦斯價格' span={1}>
                            <Space align='baseline'>
                                {phoneCus.cisGasPriceList.length > 0 ? (
                                    <List
                                        size='small'
                                        grid={2}
                                        dataSource={phoneCus.cisGasPriceList}
                                        split
                                        style={{ marginTop: 10 }}
                                        renderItem={(ele, index) => (
                                            <List.Item
                                                style={{
                                                    marginTop: -10,
                                                }}
                                                key={index}
                                            >
                                                {ele.gas_cylinder_info.kilogram +
                                                    'KG $' +
                                                    ele.price}
                                            </List.Item>
                                        )}
                                    />
                                ) : (
                                    '-'
                                )}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label='借桶數量'>
                            {phoneCus.oweCylinderList.length > 0 ? (
                                <List
                                    size='small'
                                    grid={2}
                                    dataSource={phoneCus.oweCylinderList}
                                    bordered={false}
                                    split={false}
                                    style={{ marginTop: 10 }}
                                    renderItem={(item, index) => (
                                        <List.Item key={index} style={{ marginTop: -10 }}>
                                            {item.cylinder_specification +
                                                'KG ' +
                                                item.total_owe_cylinder +
                                                '桶'}
                                        </List.Item>
                                    )}
                                />
                            ) : (
                                '-'
                            )}
                        </Descriptions.Item>
                    </Descriptions>

                    <div style={{ marginRight: -70, marginLeft: -50, width: 670 }}>
                        <Tabs
                            defaultActiveKey='1'
                            items={[
                                {
                                    label: `付款紀錄（最近5筆）`,
                                    key: '1',
                                    children: (
                                        <Table
                                            columns={CusBillColumns('phone').filter(
                                                (data) =>
                                                    data.title !== '配送員' &&
                                                    data.title !== '付款編號',
                                            )}
                                            dataSource={payRecord}
                                            bordered
                                            size='small'
                                            scroll={{ y: 190 }}
                                            pagination={false}
                                            rowKey='order_payup_id'
                                        />
                                    ),
                                },
                                {
                                    label: `訂單紀錄（最近5筆）`,
                                    key: '2',
                                    children: (
                                        <Table
                                            columns={CusBillColumns('phone', 'order').filter(
                                                (data) =>
                                                    data.title !== '付款/付欠款' &&
                                                    data.title !== '付款編號' &&
                                                    data.key !== 'pay_way',
                                            )}
                                            dataSource={phoneCus.orderList}
                                            size='small'
                                            bordered
                                            scroll={{ y: 190 }}
                                            pagination={false}
                                            rowKey='order_id'
                                        />
                                    ),
                                },
                            ]}
                        />
                    </div>
                </>
            ),
            placement: 'bottomLeft',
        });
    }, [phoneCus]);
};
