import { message } from 'antd';
import { getErrorMsg } from './PublicApi';
import { fetchApi } from './ApiService';

const ErrorMsg = async (error) => {
    let msgCode = error.response.data?.message.errorCode;
    if (msgCode) {
        const result = await fetchApi(getErrorMsg, msgCode);
        if (result.data.error_front_message) {
            message.error(result.data.error_front_message);
        } else {
            message.error(`${error.message}/發生錯誤 `);
        }
    } else {
        message.error(`發生錯誤 `);
    }
};
export default ErrorMsg;
