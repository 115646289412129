import { useState } from 'react';
import SendEmail from './SendEmail';
import NewPassword from './NewPassword';

const ForgetPassword = () => {
    const [current, setCurrent] = useState(0);
    return current === 0 ? (
        <SendEmail next={(v) => setCurrent(v)} />
    ) : (
        <NewPassword pre={(v) => setCurrent(v)} />
    );
};
export default ForgetPassword;
