import { CheckCircleOutlined, LockTwoTone } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import style from '../Login.module.scss';
import { useNavigate } from 'react-router-dom';
const { Title, Text } = Typography;

const NewPassword = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const onFinish = () => {
        navigate(-1);
    };
    return (
        <Form form={form} onFinish={onFinish} autoComplete='off'>
            <div className={style.box}>
                <Form.Item className={style.forget_header}>
                    <LockTwoTone className={style.forget_icon} />
                    <Title level={2} className={style.forget_title}>
                        輸入修改新密碼的電郵
                    </Title>
                </Form.Item>
                <div style={{ width: '50%', margin: 'auto', marginTop: 50 }}>
                    <Text>新密碼</Text>
                    <Form.Item
                        name='password'
                        style={{ marginTop: 10 }}
                        rules={[{ required: true, message: '請輸入密碼' }]}
                    >
                        <Input.Password size='large' placeholder='密碼' />
                    </Form.Item>
                </div>
                <div style={{ width: '50%', margin: 'auto', marginTop: 50 }}>
                    <Text>確認新密碼</Text>
                    <Form.Item
                        name={'repassword'}
                        style={{ marginTop: 10 }}
                        dependencies={['password']}
                        className={style.forget_input}
                        rules={[
                            {
                                required: true,
                                message: `請輸入新密碼`,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('密碼並不一致'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password size='large' placeholder='再次輸入密碼' />
                    </Form.Item>
                </div>
                <br />
                <br />
                <Row
                    align='middle'
                    justify='space-between'
                    style={{ width: '50%', margin: 'auto' }}
                >
                    <Col>
                        <Form.Item noStyle>
                            <Button size='large' htmlType='submit'>
                                取消
                            </Button>
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button
                                size='large'
                                type='primary'
                                ghost
                                style={{ marginLeft: 10 }}
                                onClick={() => props.pre(0)}
                            >
                                上一步
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item noStyle>
                            <Button
                                size='large'
                                type='primary'
                                htmlType='submit'
                                icon={<CheckCircleOutlined />}
                                // loading={loading}
                            >
                                確定
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Form>
    );
};
export default NewPassword;
