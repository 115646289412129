import { Progress, Space, Spin, Typography } from 'antd';
const { Text } = Typography;
const WebsocketStatus = (props) => {
    const status =
        props.websocketData.topic !== 'connection_error'
            ? true
            : props.websocketData.topic === 'connection' && false;
    const loading = props.websocketData.topic === 'reconnection';
    return (
        <Space>
            {loading ? (
                <Spin />
            ) : (
                <Progress
                    percent={100}
                    steps={6}
                    showInfo={false}
                    size='small'
                    strokeColor={status ? '#03b100' : '#f5222d'}
                />
            )}
            <Text style={{ color: 'white' }}>
                {loading ? '重連中' : status ? '已連線' : '斷線'}
            </Text>
        </Space>
    );
};
export default WebsocketStatus;
