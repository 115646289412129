import { Button, notification, Typography } from 'antd';
import { DataType } from './DataType';
import { BulbTwoTone } from '@ant-design/icons';
import { useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../layouts';
const { Text } = Typography;
export const Notice = () => {
    const navigate = useNavigate();
    const [noticeData, setNoticeData] = useState({});
    const context = useContext(AppContext);
    const { firebaseNotice, readMode } = context;
    let key = 'Notice';
    let timeHandle = useRef(null);
    useMemo(() => {
        if (firebaseNotice) {
            if (timeHandle.current) {
                clearTimeout(timeHandle.current);
            }
            timeHandle.current = setTimeout(() => {
                notification.close(key);
                setNoticeData({});
            }, 5000);
            let data = JSON.parse(firebaseNotice?.notification.body);
            setNoticeData(DataType(data, 'notice'));
        }
    }, [firebaseNotice]);

    return (
        readMode &&
        noticeData.title &&
        notification.info({
            style: { width: 350, marginTop: -15, marginLeft: -15 },
            icon: noticeData?.icon ? (
                <Button
                    type='primary'
                    shape='circle'
                    icon={noticeData?.icon}
                    onClick={() => {
                        navigate(noticeData.url);
                        setNoticeData({});
                    }}
                />
            ) : (
                <BulbTwoTone />
            ),
            key,
            duration: 0,
            placement: 'bottomRight',
            onClose: () => setNoticeData({}),
            message: (
                <>
                    <Text strong style={{ fontSize: 17, marginBottom: 20 }}>
                        {noticeData?.title}
                    </Text>
                    <div style={{ marginTop: 10 }} />
                    <Text>
                        {noticeData?.name !== 'system' && noticeData?.name}
                        {noticeData?.content}
                    </Text>
                </>
            ),
        })
    );
};
