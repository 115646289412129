import { Tag } from 'antd';

const OrderListTag = (stepList, record) => {
    let newStepList = [...stepList];
    const { order_list_order_delivery_status, order_list_order_status } = record;
    newStepList.splice(3, 0, {
        title: '配送完成',
        tagColor: 'purple',
    });
    const status =
        order_list_order_delivery_status === '已完成訂單' && order_list_order_status === '配送中'
            ? '配送完成'
            : order_list_order_status;

    return <Tag color={newStepList.find((item) => item.title === status)?.tagColor}>{status}</Tag>;
};
export default OrderListTag;
