import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const UseSuppiler = () => {
    const token = localStorage.getItem('userId');
    let url = '/aw-api/supplier';
    const navigate = useNavigate();
    const [supplier, setSupplier] = useState();
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_KEY}${url}`, {
            method: 'GET',
            headers: {
                Authorization: token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.status === 403) {
                    localStorage.removeItem('userId');
                    document.cookie = 'userId=;';
                    navigate('/loading');
                } else if (response.status === 200) {
                    setSupplier(response.data[0]);
                }
            });
    }, []);

    return { supplier };
};
export default UseSuppiler;
