import DetailButton from './Order/DetailButton';
import MainTabList from './Order/MainTabList';
import OrderListAction from './Order/OrderListAction';
import OrderListTag from './Order/OrderListTag';
import { orderStepList, orderStepCurrent } from './Order/OrderStepList';

const PremiumPlan = () => {
    return {
        order: {
            MainTabList,
            orderStepList,
            orderStepCurrent,
            OrderListAction,
            OrderListTag,
            DetailButton,
        },
    };
};

export default PremiumPlan;
