import { Badge, Button, Card, Space, Spin, Tag } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { CloseOutlined, PhoneOutlined } from '@ant-design/icons';
import { PopUpWindow } from './PopUpWindow';
import style from './PhoneNotice.module.scss';
import { AppContext } from '../../../layouts';
import { useLocation } from 'react-router-dom';
const { Meta } = Card;

export const PhoneNotice = () => {
    const location = useLocation();
    const [phoneLineList, setPhoneLineList] = useState([]);
    const [open, setOpen] = useState(false);
    const [animat, setAnimat] = useState(false);
    const [newPhone, setNewPhone] = useState({ status: false, data: null });
    const context = useContext(AppContext);
    const { websocketData } = context;

    const onClose = () => {
        if (location.pathname.includes('NewOrders')) {
            setOpen(false);
            setNewPhone({ status: false, data: null });
        }
    };
    useMemo(() => {
        if (Array.isArray(websocketData.phoneLineList)) {
            setOpen(true);
            websocketData.ring?.is_ringing && setAnimat(true);
            setPhoneLineList(websocketData.phoneLineList);
            setTimeout(() => {
                setAnimat(false);
            }, 1000);
        }
        if (websocketData.ring) {
            if (
                !websocketData.ring.is_ringing &&
                websocketData.ring.phone_line === newPhone.data?.phone_line
            ) {
                setNewPhone({ status: false, data: null });
            }
        }
        onClose();
    }, [websocketData.phoneLineList]);
    useMemo(() => {
        if (location.pathname.includes('NewOrders')) {
            onClose();
        }
    }, [location]);

    return (
        <div>
            <div style={{ position: 'fixed', bottom: 20, left: 30, zIndex: 9999 }}>
                <div style={{ bottom: 65 }}>
                    {open &&
                        phoneLineList.map((data, index) => (
                            <Card
                                style={{ width: 300 }}
                                key={index}
                                hoverable={true}
                                onClick={() => {
                                    if (!data.loading) {
                                        if (data.manyCus) {
                                            setNewPhone({ status: false, data: null });
                                        } else {
                                            if (newPhone.data !== null) {
                                                if (newPhone.data.phone_line === data.phone_line) {
                                                    setNewPhone({ status: false, data: null });
                                                } else {
                                                    setNewPhone({ status: true, data: data });
                                                }
                                            } else {
                                                setNewPhone({ status: true, data: data });
                                            }
                                        }
                                    }
                                }}
                            >
                                {!data.loading ? (
                                    <Meta
                                        key={index}
                                        avatar={
                                            <Space direction='vertical' style={{ fontSize: 16 }}>
                                                {index + 1}
                                                <Tag
                                                    icon={<PhoneOutlined />}
                                                    style={{ fontSize: 16 }}
                                                >
                                                    {data.phone_line}
                                                </Tag>
                                            </Space>
                                        }
                                        description={
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold',
                                                    color: 'black',
                                                }}
                                            >
                                                {data.phoneCus.customerInfos ? (
                                                    <div>
                                                        <div
                                                            style={{
                                                                whiteSpace: 'pre-wrap',
                                                            }}
                                                        >
                                                            <text>
                                                                {
                                                                    data.phoneCus.customerInfos
                                                                        .customer_info.customer_name
                                                                }
                                                            </text>
                                                        </div>
                                                        {data.phoneCus.phone_number}
                                                    </div>
                                                ) : (
                                                    <Space>
                                                        {data.phoneCus.phone_number}
                                                        {data.manyCus && (
                                                            <Tag color='volcano'>多客戶</Tag>
                                                        )}
                                                    </Space>
                                                )}
                                            </div>
                                        }
                                    />
                                ) : (
                                    <Meta
                                        key={index}
                                        avatar={<Spin />}
                                        title={
                                            <>
                                                新來電 <br />
                                                {data.phoneCus.phone_number}
                                            </>
                                        }
                                    />
                                )}
                            </Card>
                        ))}
                </div>
                <div className={animat ? style.shakeAnimation : null}>
                    <Space>
                        <Badge count={phoneLineList.length}>
                            <Button
                                type='primary'
                                shape='circle'
                                icon={<PhoneOutlined />}
                                disabled={phoneLineList.length <= 0}
                                size='large'
                                onClick={() => {
                                    setOpen((p) => !p);
                                    setNewPhone({ status: false, data: null });
                                }}
                            />
                        </Badge>
                        <Button
                            type='primary'
                            shape='circle'
                            danger
                            icon={<CloseOutlined />}
                            disabled={phoneLineList.length <= 0}
                            size='large'
                            onClick={() => {
                                setOpen((p) => !p);
                                setNewPhone({ status: false, data: null });
                                setPhoneLineList([]);
                            }}
                        />
                    </Space>
                </div>
            </div>

            <PopUpWindow
                phoneCusData={newPhone.data}
                status={newPhone.status}
                setOpen={() => {
                    setNewPhone({ status: false, data: null });
                }}
            />
        </div>
    );
};
