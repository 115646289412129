import { MailTwoTone } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import style from '../Login.module.scss';
import { useNavigate } from 'react-router-dom';
const { Title } = Typography;

const SendEmail = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const onFinish = () => {
        props.next(1);
    };
    return (
        <Form form={form} onFinish={onFinish} autoComplete='off'>
            <div className={style.box}>
                <Form.Item className={style.forget_header}>
                    <MailTwoTone className={style.forget_icon} />
                    <Title level={2} className={style.forget_title}>
                        輸入修改新密碼的電郵
                    </Title>
                    <Title
                        level={3}
                        style={{
                            fontWeight: 400,
                            fontSize: 19,
                            color: '#096dd9',
                            marginBottom: 20,
                        }}
                    >
                        系統會寄出郵件，讓你修改密碼
                    </Title>
                </Form.Item>
                <Form.Item
                    name='email'
                    className={style.input}
                    wrapperCol={{ span: 13 }}
                    rules={[{ required: true, message: '請輸入Email', type: 'email' }]}
                >
                    <Input
                        size='large'
                        placeholder=' Email'
                        prefix={<MailTwoTone className={style.icon} />}
                    />
                </Form.Item>
                <br />
                <br />
                <Form.Item className={style.submit} wrapperCol={{ span: 13 }}>
                    <Button
                        className={style.submitSize}
                        size='large'
                        type='primary'
                        htmlType='submit'
                        // loading={loading}
                    >
                        寄出
                    </Button>
                </Form.Item>
                <Form.Item className={style.submit} wrapperCol={{ span: 13 }}>
                    <Button className={style.submitSize} size='large' onClick={() => navigate(-1)}>
                        取消
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};
export default SendEmail;
