import {
    CodeOutlined,
    UserOutlined,
    FireOutlined,
    TeamOutlined,
    CodeTwoTone,
    CarryOutFilled,
    CarryOutTwoTone,
    FireTwoTone,
    SettingOutlined,
    CheckSquareOutlined,
    ContainerOutlined,
} from '@ant-design/icons';
import style from './Notification.module.scss';
export const DataType = (item, from) => {
    let title,
        content,
        icon,
        url,
        name = item.notifier_name;
    let id = item.note ? item.note.change_id : null;

    switch (item.notification_type) {
        case 'new_order':
            title = '新訂單';
            content = '下訂了新訂單';
            url = `/orders/AllOders/OrdersDetailed/${id}`;
            break;
        case 'new_customer':
            title = '新客戶';
            content = '註冊了新帳號';
            url = `/customer/CustomerList/CustomerDetailed/${id}`;
            break;
        case 'new_device':
            title = '新裝置';
            content = '激活了新裝置';
            url = `/device/AllDevice/DeviceDetailed/${id}`;
            break;
        case 'update_order':
            title = '訂單更新';
            content = '更新了訂單內容';
            url = `/orders/AllOders/OrdersDetailed/${id}`;
            break;
        case 'low_gas':
            title = '低壓通報';
            content = '的裝置發出了低壓通知';
            url = `/device/AllDevice/DeviceDetailed/${id}`;
            break;
        case 'create_user':
            title = '新使用者';
            content = '新增了使用者';
            url = `/account/AllAcc/AccDetailed/${id}`;
            break;
        case 'delete_user':
            title = '刪除使用者';
            content = '刪除了使用者';
            break;
        case 'month_end_settlement':
            title = '月結時間到了';
            content = '該清算月結客戶的帳單';
            break;
        case 'courier_finish_order':
            title = '配送員完成訂單';
            content = `已經完成${id}`;
            url = `/orders/AllOders/OrdersDetailed/${id}`;
            break;
        case 'courier_invoice_order':
            title = '配送員開立發票';
            content = `為${id}開立了發票`;
            url = `/orders/AllOders/OrdersDetailed/${id}`;
            break;
    }
    switch (item.notification_type) {
        case 'order':
            icon =
                from !== 'notice' ? (
                    <CarryOutFilled className={style.newIcon} />
                ) : (
                    <CarryOutTwoTone />
                );
            break;
        case 'customer':
            icon = (
                <UserOutlined className={from !== 'notice' ? style.newIcon : style.noticeIcon} />
            );
            break;
        case 'device':
            icon = from !== 'notice' ? <CodeOutlined className={style.newIcon} /> : <CodeTwoTone />;
            break;
        case 'gas':
            icon =
                from !== 'notice' ? (
                    <FireOutlined className={from !== 'notice' && style.newIcon} />
                ) : (
                    <FireTwoTone />
                );
            break;
        case 'user':
            icon = (
                <TeamOutlined className={from !== 'notice' ? style.newIcon : style.noticeIcon} />
            );
            break;
        case 'system':
            icon = (
                <SettingOutlined className={from !== 'notice' ? style.newIcon : style.noticeIcon} />
            );
            break;
        case 'courier_finish_order':
            icon = (
                <CheckSquareOutlined
                    className={from !== 'notice' ? style.newIcon : style.noticeIcon}
                />
            );
            break;
        case 'courier_invoice_order':
            icon = (
                <ContainerOutlined
                    className={from !== 'notice' ? style.newIcon : style.noticeIcon}
                />
            );
            break;
        default:
            break;
    }
    return { title, name, content, icon, url };
};
