import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../node_modules/leaflet/dist/leaflet.css';
import App from './App';
import 'antd/dist/antd.min.css';
import { ConfigProvider } from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';
import 'moment/locale/zh-tw';
import HelmetData from './plugins/helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider locale={zhTW}>
        <App />
        <HelmetData />
    </ConfigProvider>,
);
