import { BellOutlined } from '@ant-design/icons';
import {
    Badge,
    Button,
    Col,
    Divider,
    Popover,
    Row,
    Skeleton,
    Tabs,
    Tooltip,
    Typography,
    List,
    Space,
    message,
    Switch,
} from 'antd';
import style from './Notification.module.scss';
import { useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { DataType } from './DataType';
import { AppContext } from '../../layouts';
import { Notice } from './Notice';
import { fetchApi } from '../../services/ApiService';
import { getNoticeList, patchAllNotice, putReadMode } from '../../services/NoticeApi';

const { Title } = Typography;
const { TabPane } = Tabs;
const Notification = () => {
    const navigate = useNavigate();
    const [noticeList, setNoticeList] = useState([]);
    const [noticeListLength, setNoticeListLength] = useState({});
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [listState, setListState] = useState(null);
    const [newNotice, setNewNotice] = useState(false);
    const [open, setOpen] = useState();
    const context = useContext(AppContext);
    const { firebaseNotice, readMode, setReadMode } = context;
    let token = localStorage.getItem('userId');
    let key = 'notification';

    useEffect(() => {
        if (token) {
            FetchNotice(0);
        }
    }, []);

    const FetchNotice = async (key, state) => {
        try {
            key === 0 && setLoading(true);
            const res = await fetchApi(getNoticeList, {
                page: key,
                size: 5,
                read_status: state === 'null' ? null : state || listState,
            });
            key === 0
                ? setNoticeList(res.data.notificationList)
                : setNoticeList([...noticeList, ...res.data.notificationList]);
            setNoticeListLength({
                rowCount: res.data.rowCount,
                unreadNumber: res.data.unreadNumber,
            });
            setPage(key);
            setLoading(false);
        } catch (error) {
            message.error({ content: '讀取失敗', key });
        }
    };
    useMemo(() => {
        if (firebaseNotice) {
            FetchNotice(0);
            setNewNotice(true);
        }
    }, [firebaseNotice]);

    const ChangeState = (id, readStatus) => {
        let array = [];
        array = id
            ? [id]
            : noticeList.map((item) => {
                  !item.read_status && array.push(item.notification_id);
              });
        if (array.length > 0) {
            fetch(process.env.REACT_APP_API_KEY + '/aw-api/notification/list', {
                method: 'PATCH',
                headers: {
                    Authorization: token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    read_status: true,
                    notification_id_list: array,
                }),
            }).then(() => {
                if (!readStatus) {
                    id && message.success({ content: '已更新', key });
                    setNoticeListLength({
                        ...noticeListLength,
                        unreadNumber: noticeListLength.unreadNumber - 1,
                    });
                }
            });
        } else {
            message.success({ content: '已更新', key });
        }
    };

    const readAllMsg = async () => {
        message.loading({ content: '更新中', key });
        const result = await fetchApi(patchAllNotice);
        if (result.status === 200) {
            message.success({ content: '已更新', key });
            FetchNotice(0);
        } else {
            message.error({ content: '更新失敗', key });
        }
    };

    const settingReadMode = async (mode) => {
        const result = await fetchApi(putReadMode, {
            setting_list: [
                {
                    setting_page: 'enable_notification',
                    setting_name: '讀取資料',
                    setting_content: {
                        read: mode,
                    },
                },
            ],
        });
        if (result.status === 200) {
            setReadMode(mode);
        }
    };
    const onClick = (obj, index) => {
        let array = noticeList;
        obj['read_status'] = true;
        array.splice(index, 1, obj);
        setNoticeList(array);
    };
    const Content = (id) => {
        return loading ? (
            <Skeleton />
        ) : (
            <div
                id={id}
                style={{
                    height: 400,
                    width: 370,
                    overflow: 'auto',
                    padding: '0 16px',
                }}
            >
                <InfiniteScroll
                    dataLength={noticeList.length}
                    next={() => {
                        FetchNotice(page + 1);
                    }}
                    hasMore={noticeList.length < noticeListLength.rowCount}
                    loader={
                        <Skeleton
                            avatar
                            paragraph={{
                                rows: 1,
                            }}
                            active
                        />
                    }
                    endMessage={<Divider plain>已加載完畢</Divider>}
                    scrollableTarget={id}
                >
                    <List
                        dataSource={noticeList}
                        loading={loading}
                        renderItem={(item, index) => {
                            let data = DataType(item, 'list');
                            return (
                                <List.Item
                                    key={item.notification_id}
                                    onClick={() => {
                                        navigate(data.url);
                                        ChangeState(item.notification_id, item.read_status);
                                        onClick(item, index);
                                    }}
                                    style={{
                                        color: item.read_status && 'gray',
                                    }}
                                >
                                    <List.Item.Meta
                                        avatar={data.icon}
                                        title={
                                            <Title
                                                level={5}
                                                style={{ color: item.read_status && 'gray' }}
                                            >
                                                {data.title}
                                            </Title>
                                        }
                                        description={
                                            <div
                                                style={{
                                                    color: item.read_status ? 'gray' : 'black',
                                                }}
                                            >
                                                {item.notifier_name !== 'system'
                                                    ? item.notifier_name
                                                    : ''}{' '}
                                                {data.content ? data.content : ''}
                                            </div>
                                        }
                                    />
                                    <Space>
                                        {!item.read_status ? (
                                            <Badge color='blue' />
                                        ) : (
                                            <div style={{ width: 20 }} />
                                        )}
                                        {data.url && (
                                            <a
                                                onClick={() => {
                                                    navigate(data.url);
                                                    ChangeState(
                                                        item.notification_id,
                                                        item.read_status,
                                                    );
                                                }}
                                            >
                                                查看詳細
                                            </a>
                                        )}
                                    </Space>
                                </List.Item>
                            );
                        }}
                    />
                </InfiniteScroll>
            </div>
        );
    };

    const noticeLayouts = () => {
        return (
            <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                <Row align='baseline' justify='space-between'>
                    <Col>
                        <Space align='baseline'>
                            <Title level={4}>通知</Title>
                            <Switch
                                checked={readMode}
                                checkedChildren='接收'
                                unCheckedChildren='關閉'
                                onChange={settingReadMode}
                            />
                        </Space>
                    </Col>

                    <Col>
                        <Button onClick={readAllMsg}>全部標示已讀</Button>
                    </Col>
                </Row>
                <Tabs
                    style={{ marginTop: 20 }}
                    defaultActiveKey='null'
                    onChange={(key) => {
                        FetchNotice(0, key);
                        setListState(key === 'null' ? null : key);
                    }}
                >
                    <TabPane tab='全部' key='null'>
                        <div style={{ marginTop: 10 }}>{Content('allList')}</div>
                    </TabPane>
                    <TabPane tab='已讀' key='true'>
                        <div style={{ marginTop: 10 }}>{Content('readList')}</div>
                    </TabPane>
                    <TabPane tab='未讀' key='false'>
                        <div style={{ marginTop: 10 }}>{Content('unReadList')}</div>
                    </TabPane>
                </Tabs>
            </div>
        );
    };
    useMemo(() => {
        if (open === false) {
            FetchNotice(0);
            setNoticeList([]);
        }
    }, [open]);
    return (
        <>
            <Popover
                overlayInnerStyle={{
                    height: 600,
                    width: 400,
                    marginRight: 10,
                    marginTop: 10,
                }}
                placement='bottom'
                content={noticeLayouts}
                trigger='click'
                destroyTooltipOnHide={true}
                onVisibleChange={(v) => setOpen(v)}
            >
                <Tooltip title='通知'>
                    <div className={newNotice ? style.shakeAnimation : null}>
                        <Badge count={noticeListLength.unreadNumber}>
                            <Button
                                style={{
                                    backgroundColor: '#003a8c',
                                    borderColor: '#003a8c',
                                }}
                                type='primary'
                                shape='circle'
                                icon={<BellOutlined />}
                                onClick={() => {
                                    FetchNotice(0);
                                }}
                            />
                        </Badge>
                    </div>
                </Tooltip>
            </Popover>
            <Notice />
        </>
    );
};
export default Notification;
