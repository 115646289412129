import MainTabList from './Order/MainTabList';
import OrderListTag from './Order/OrderListTag';
import { orderStepList, orderStepCurrent } from './Order/OrderStepList';

const BasicPlan = () => {
    return {
        order: {
            MainTabList,
            orderStepList,
            orderStepCurrent,
            OrderListAction: () => null,
            OrderListTag,
            DetailButton: () => null,
        },
    };
};

export default BasicPlan;
