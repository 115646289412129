import BasicPlan from './BasicPlan';
import PremiumPlan from './PremiumPlan';
import UltimatePlan from './UltimatePlan';

const ServicePlanFactory = (level) => {
    let plan;
    switch (level) {
        case 'Basic':
            plan = BasicPlan();
            break;
        case 'Premium':
            plan = PremiumPlan();
            break;
        case 'Ultimate':
            plan = UltimatePlan();
            break;
        default:
            break;
    }
    return plan;
};

export default ServicePlanFactory;
