import { useEffect } from 'react';
import { useNavigationType, useNavigate, useLocation } from 'react-router-dom';

function CheckNavigationType() {
    const navigate = useNavigate();
    const location = useLocation();
    const checkNavigationType = useNavigationType();
    useEffect(() => {
        if (checkNavigationType === 'POP') {
            navigate('loading', { state: { path: location.pathname + location.search } });
        }
    }, []);
}
export default CheckNavigationType;
