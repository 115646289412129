import { apiRequest } from './ApiService';

export const getOrderCusOrderByPhone = (body) =>
    apiRequest().get('/aw-api/order/customer/phone-search', {
        params: { ...body },
    });

export const getAllOrder = (paramsData, pathVars) =>
    apiRequest().get('/aw-api/order/list', {
        params: { ...paramsData, ...pathVars },
    });

export const getSearchOrder = (paramsData, pathVars) =>
    apiRequest().get('/aw-api/order/search', {
        params: { ...paramsData, ...pathVars },
    });

export const delOrdersTableRow = (body) => apiRequest().patch('/aw-api/order/status/list', body);

export const getOrdersData = (path) => apiRequest().get(`/aw-api/order/fast/${path}`);

export const getCusData = (params) =>
    apiRequest().get(`/aw-api/order/customer`, { params: params });

export const getCusDevice = (params) =>
    apiRequest().get(`/aw-api/order/customer/device`, { params: params });

export const putOrderData = (id, body) => apiRequest().put(`/aw-api/order/${id}`, { ...body });

export const payUpOrderBill = (body) => apiRequest().patch(`/aw-api/order/payup`, body);
