import { fetchApi } from '../../../services/ApiService';
import { getOrderCusOrderByPhone } from '../../../services/OrderApi';
import { getCusDetailByID, getCusDetailByPhone } from '../../../services/PhoneRecordApi';
export const FetchCusData = async (body, searchType) => {
    let res = null;
    searchType === 'cis_id'
        ? (res = await fetchApi(getCusDetailByID, body))
        : searchType === 'order'
        ? (res = await fetchApi(getOrderCusOrderByPhone, body))
        : (res = await fetchApi(getCusDetailByPhone, body));

    let result = [];
    result =
        res.data &&
        res.data.customerPayupRecords?.orderPayupList.map((item) => ({
            create_time_stamp: item.order_payup_work_info.bill_of_sale_work_info.create_time_stamp
                ? new Date(
                      Date.parse(
                          item.order_payup_work_info.bill_of_sale_work_info.create_time_stamp,
                      ),
                  ).toLocaleDateString()
                : '-',
            courier_name:
                item.order_payup_work_info.bill_of_sale_work_info.courier_info?.courier_name || '-',
            courier_id:
                item.order_payup_work_info.bill_of_sale_work_info.courier_info?.courier_id || '-',
            pay_way: item.order_payup_work_info.pay_way,
            order_payup_id: item.order_payup_id,
            payment_amount: item.order_payup_work_info.payment_amount,
            is_arrears_order: item.is_arrears_order ? '付欠款' : '付款',
            order_id: item.order_info?.order_id,
            order_commodity_list: item.order_info?.order_commodity_list,
            order_cylinder_list: item.order_info?.order_cylinder_list,
            cis_cylinder_mortgage_list: item.order_info?.cis_cylinder_mortgage_list,
            order_usage_fee_list: item.order_info?.order_usage_fee_list,
            order_gas_list: item.order_info?.order_gas_list
                ? item.order_info.order_gas_list.map((orderItem) => {
                      let price_info = orderItem.gas_price_info
                          ? orderItem.gas_price_info
                          : orderItem.cis_gas_price_info;
                      return {
                          numbers_of_cylinder: orderItem.numbers_of_cylinder,
                          gas_type: price_info.gas_cylinder_info.gas_type,
                          kilogram: price_info.gas_cylinder_info.kilogram,
                      };
                  })
                : [],
        }));
    let orderList = [];
    orderList =
        res.data &&
        res.data.orderList?.map((item) => ({
            order_id: item.order_list_order_id,
            payment_amount: item.total_price,
            courier_name: item.courier_courier_name,
            delivery_time_stamp: new Date(
                Date.parse(item.order_list_delivery_time_stamp),
            ).toLocaleDateString(),
            order_commodity_list: item.extra_element[0].order_commodity_list,
            order_cylinder_list: item.extra_element[0].order_cylinder_list,
            cis_cylinder_mortgage_list: item.extra_element[0].cis_cylinder_mortgage_list,
            order_usage_fee_list: item.extra_element[0].order_usage_fee_list,
            order_gas_list:
                item.extra_element[0].order_gas_list.length > 0
                    ? item.extra_element[0].order_gas_list.map((orderItem) => {
                          let price_info = orderItem.gas_price_info
                              ? orderItem.gas_price_info
                              : orderItem.cis_gas_price_info;
                          return {
                              numbers_of_cylinder: orderItem.numbers_of_cylinder,
                              gas_type: price_info.gas_cylinder_info.gas_type,
                              kilogram: price_info.gas_cylinder_info.kilogram,
                          };
                      })
                    : [],
        }));
    return res.data
        ? {
              ...res.data,
              orderList,
              cisGasPriceList: res.data.cisGasPriceList,
              customerPayupRecords: result ? (result.length > 0 ? result : null) : null,
          }
        : null;
};
