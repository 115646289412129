import { Tag } from 'antd';

const OrderListTag = (stepList, record) => {
    const { order_list_order_status } = record;

    return (
        <Tag color={stepList.find((item) => item.title === order_list_order_status)?.tagColor}>
            {order_list_order_status}
        </Tag>
    );
};
export default OrderListTag;
