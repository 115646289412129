import { Col } from 'antd';
import style from '../../../scss.module.scss';
const OrderListAction = (setupFunction, data) => {
    const { setMoneyModalOpen, setConfirmData } = setupFunction;
    const { record } = data;

    return (
        record.order_list_order_delivery_status === '已完成訂單' &&
        record.order_list_order_status === '配送中' && (
            <Col span={8}>
                <a
                    onClick={() => {
                        setMoneyModalOpen(true);
                        setConfirmData(record);
                    }}
                    className={style.tableSureButton}
                >
                    訂單完成
                </a>
            </Col>
        )
    );
};
export default OrderListAction;
