import { Col, Row, Tag } from 'antd';
import cusStyle from '../../Cus.module.scss';
import phoneStyle from '../../../../components/PhoneRing/PhoneNotice/PhoneNotice.module.scss';
import { ToMonetFormat } from '../../../../function/ToMoneyFormat';
export const CusBillColumns = (from, mode) => {
    let orderMode = mode === 'order';
    return [
        {
            title: '編號',
            dataIndex: orderMode ? 'order_id' : 'order_payup_id',
            key: orderMode ? 'order_id' : 'order_payup_id',
            width: 80,
        },
        {
            title: orderMode ? '派送日期' : '付款日期',
            dataIndex: orderMode ? 'delivery_time_stamp' : 'create_time_stamp',
            key: orderMode ? 'delivery_time_stamp' : 'create_time_stamp',
            width: from === 'phone' ? 100 : 150,
        },
        {
            title: from === 'phone' ? '方式' : '付款方式',
            dataIndex: 'pay_way',
            key: 'pay_way',
            width: from === 'phone' ? 60 : 100,
        },
        {
            title: '付款/欠款',
            dataIndex: 'is_arrears_order',
            key: 'is_arrears_order',
            render: (text) =>
                text ? <Tag color={text === '付欠款' ? 'volcano' : 'blue'}>{text}</Tag> : '-',
            width: from === 'phone' ? 90 : 120,
        },
        {
            title: '訂單品項',
            dataIndex: 'order_gas_list',
            key: 'order_gas_list',
            width: from === 'phone' ? 220 : 400,
            render: (text, record) => {
                const result = {};
                let mortgage = {};
                let fee = {};
                let cylinder = {};
                let comm = {};
                record.order_gas_list?.map((item) => {
                    !result[item.gas_type]
                        ? (result[item.gas_type] = [item])
                        : result[item.gas_type].push(item);
                });
                record.cis_cylinder_mortgage_list?.map((item) => {
                    !mortgage[item.take_cylinder_type]
                        ? (mortgage[item.take_cylinder_type] = [item])
                        : mortgage[item.take_cylinder_type].push(item);
                });
                record.order_usage_fee_list?.map((item) => {
                    !fee['使用費'] ? (fee['使用費'] = [item]) : fee['使用費'].push(item);
                });
                record.order_cylinder_list?.map((item) => {
                    !cylinder[item.cylinder_price_info.customer_action_type]
                        ? (cylinder[item.cylinder_price_info.customer_action_type] = [item])
                        : cylinder[item.cylinder_price_info.customer_action_type].push(item);
                });
                record.order_commodity_list?.map((item) => {
                    !comm['商品'] ? (comm['商品'] = [item]) : comm['商品'].push(item);
                });
                return (
                    <Row gutter={[10, 10]}>
                        {Object.keys(result).map((key) => {
                            return (
                                <Col key={key} style={{ marginBottom: 5 }}>
                                    {key} ：
                                    {result[key].map((ele, index) => (
                                        <Tag
                                            key={index}
                                            style={{ fontSize: text === 'phone' && 18 }}
                                            className={
                                                from === 'phone' ? phoneStyle.tag : cusStyle.billTag
                                            }
                                        >
                                            {ele.kilogram}KG x{ele.numbers_of_cylinder}
                                        </Tag>
                                    ))}
                                </Col>
                            );
                        })}
                        {Object.keys(mortgage).map((key, index) => {
                            return (
                                <Col key={index} style={{ marginBottom: 5 }}>
                                    {key} ：
                                    {mortgage[key].map((ele, index) => (
                                        <Tag
                                            key={index}
                                            style={{ fontSize: text === 'phone' && 18 }}
                                            className={
                                                from === 'phone' ? phoneStyle.tag : cusStyle.billTag
                                            }
                                        >
                                            {ele.cylinder_specification}KG x
                                            {ele.numbers_of_cylinder}
                                        </Tag>
                                    ))}
                                </Col>
                            );
                        })}
                        {Object.keys(fee).map((key, index) => {
                            return (
                                <Col key={index} style={{ marginBottom: 5 }}>
                                    {key} ：
                                    {fee[key].map((ele, index) => (
                                        <Tag
                                            key={index}
                                            style={{ fontSize: text === 'phone' && 18 }}
                                            className={
                                                from === 'phone' ? phoneStyle.tag : cusStyle.billTag
                                            }
                                        >
                                            ${ele.money} x{ele.number_of_records}
                                        </Tag>
                                    ))}
                                </Col>
                            );
                        })}
                        {Object.keys(cylinder).map((key) => {
                            return (
                                <Col key={key} style={{ marginBottom: 5 }}>
                                    {key} ：
                                    {cylinder[key].map((ele, index) => (
                                        <Tag
                                            key={index}
                                            style={{ fontSize: text === 'phone' && 18 }}
                                            className={
                                                from === 'phone' ? phoneStyle.tag : cusStyle.billTag
                                            }
                                        >
                                            {ele.cylinder_price_info.cylinder_specification}KG x
                                            {ele.numbers_of_cylinder}
                                        </Tag>
                                    ))}
                                </Col>
                            );
                        })}
                        {Object.keys(comm).map((key) => {
                            return (
                                <Col key={key} style={{ marginBottom: 5 }}>
                                    {key}：
                                    {comm[key].map((ele, index) => (
                                        <Tag
                                            key={index}
                                            style={{ fontSize: text === 'phone' && 18 }}
                                            className={
                                                from === 'phone' ? phoneStyle.tag : cusStyle.billTag
                                            }
                                        >
                                            {ele.commodity_price_info.commodity_info.commodity_name}{' '}
                                            x{ele.numbers_of_commodity}
                                        </Tag>
                                    ))}
                                </Col>
                            );
                        })}
                    </Row>
                );
            },
        },
        {
            title: '配送員',
            dataIndex: 'courier_name',
            key: 'courier_name',
            width: 80,
        },
        {
            title: '金額',
            dataIndex: 'payment_amount',
            key: 'payment_amount',
            width: from === 'phone' ? 80 : 130,
            render: (text) => ToMonetFormat(text),
        },
        // {
        //    title: '動作',
        //    key: 'action',
        //    width: from === 'phone' ? 80 : 100,
        //    render: (_, record) => (
        //       <a onClick={() => navigate(`/customer/CustomerList/OrdersDetailed/${record.order_id}`)}>
        //          {from === 'phone' ? '詳細' : '查看詳細'}
        //       </a>
        //    ),
        // },
    ];
};
