import { apiRequest } from './ApiService';

export const putPageSize = (body) => apiRequest().put(`/aw-api/user/setting`, { ...body });

export const getPageSize = (paramsData, pathVars) =>
    apiRequest().get('/aw-api/user/setting/page-list', {
        params: { ...paramsData, ...pathVars },
    });

export const getErrorMsg = (id) => apiRequest().get('/aw-api/error-code/' + id);

export const subscribeFCM = (body) => apiRequest().post('/aw-api/notification/fcm', body);

export const unsubscribeFCM = (body) =>
    apiRequest().post('/aw-api/notification/fcm/unsubscribe', body);
