import { useEffect, useState } from 'react';

export function useWindowSize() {
    const getWindowSize = () => ({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
    });

    const [windowSize, setWindowSize] = useState(getWindowSize());

    const handleResize = () => {
        setWindowSize(getWindowSize());
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}
