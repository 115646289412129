import { message } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { FetchCusData } from '../PhoneRing/PhoneNotice/FetchCusData';

export const websocket = () => {
    const token = localStorage.getItem('userId');
    const [topic, setTopic] = useState('reconnection');
    const [phoneLine, setPhoneLine] = useState();
    const [SupplierPhoneLine, setSupplierPhoneLine] = useState([]);
    const [phoneRing, setPhoneRing] = useState([]);
    const [phoneCus, setPhoneCus] = useState({});
    const [notice, setNotice] = useState({});
    const [ring, setRing] = useState(null);
    const [phoneLineList, setPhoneLineList] = useState([]);
    const interval = useRef();
    const [socket, setSocket] = useState();
    const url = `${process.env.REACT_APP_WEBSOCKET_KEY}?jwt_token=${token}&role=supplier`;
    const key = 'updatable';
    let list = [{}, {}, {}, {}, {}, {}, {}, {}];
    const socketInit = () => {
        try {
            setTopic('reconnection');
            setSocket(
                io(url, {
                    transports: ['websocket'],
                }),
            );
        } catch (e) {
            message.error({ content: '讀取失敗', key });
        }
    };
    const fetchCusData = async (phone_number, phone_line, newList) => {
        const cusData = await FetchCusData({ phone_number: phone_number });
        const SlicePhoneIndex = newList.findIndex((data) => data.phone_line === phone_line);
        cusData?.customerList.length === 1
            ? (list[SlicePhoneIndex] = {
                  phoneCus: {
                      ...cusData,
                      ...{
                          customerInfos:
                              cusData.customerList.length > 0 ? cusData.customerList[0] : {},
                      },
                      phone_number,
                  },
                  payRecord: cusData.customerPayupRecords,
                  phone_line,
              })
            : (list[SlicePhoneIndex] = {
                  phoneCus: { ...cusData, phone_number },
                  ...{ manyCus: cusData?.customerList.length > 0 ? true : false },
                  phone_line,
              });
        setPhoneLineList([...list].filter((item) => item.phoneCus));
    };
    const localStorageSetItem = (phoneLine) => {
        if (phoneLine) {
            localStorage.setItem('phoneLine', phoneLine);
            document.cookie = `phoneLine=${phoneLine} `;
        } else {
            localStorage.removeItem('phoneLine');
            document.cookie = `phoneLine=`;
        }
    };
    useMemo(() => {
        if (socket) {
            socket.on('connect', () => {
                let localphoneLine = localStorage.getItem('phoneLine')?.split(',');
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
                setTopic('connection');
                localphoneLine && setPhoneLine(localphoneLine);
            });
            socket.emit('emitUserPhoneLines');
            socket.emit('getSupplierPhoneLines');
            socket.emit('emitSupplierPhoneLines');
            socket.on('get-supplier-phone-lines', () => {
                setTopic('connection');
            });
            socket.on('supplier-phone-lines', (accPhoneLines) => {
                setTopic('supplier-phone-lines');
                setSupplierPhoneLine(accPhoneLines.accessPhoneLine);
            });
            socket.on('user-phone-lines', (userPhoneLines) => {
                let localphoneLine = localStorage.getItem('phoneLine')?.split(',');
                setTopic('user-phone-lines');
                !localphoneLine && setPhoneLine(userPhoneLines.accessPhoneLine);
                setPhoneRing(userPhoneLines.phone_ring_list);
                if (!localphoneLine) {
                    localStorageSetItem(userPhoneLines.accessPhoneLine);
                }
            });
            socket.on('refresh-phone-line-setting', (refreshPhone) => {
                setTopic('refresh-phone-line-setting');
                console.warn(refreshPhone);
                let localphoneLine = localStorage.getItem('phoneLine')?.split(',');
                if (refreshPhone.accessPhoneLine) {
                    localStorageSetItem(refreshPhone.accessPhoneLine);
                }
                refreshPhone.accessPhoneLine && setPhoneLine([...refreshPhone.accessPhoneLine]);
                refreshPhone.phone_ring_list && setPhoneRing(refreshPhone.phone_ring_list);
                refreshPhone.supplierPhoneLineList &&
                    setSupplierPhoneLine(refreshPhone.supplierPhoneLineList);
                if (refreshPhone.phone_ring) {
                    if (refreshPhone.phone_ring.is_ringing) {
                        setRing({ ...refreshPhone.phone_ring, is_ringing: true });
                        setPhoneCus({
                            phone_number: refreshPhone.phone_number,
                            phone_line: refreshPhone.phone_ring.phone_line,
                        });
                        if (
                            !phoneLineList.find(
                                (data) => data.phone_line === refreshPhone.phone_ring.phone_line,
                            ) &&
                            localphoneLine[refreshPhone.phone_ring.phone_line] === 'true'
                        ) {
                            list[refreshPhone.phone_ring.phone_line] = {
                                loading: true,
                                phone_line: refreshPhone.phone_ring.phone_line,
                                phoneCus: { phone_number: refreshPhone.phone_number },
                            };
                            setPhoneLineList([...list].filter((item) => item.phoneCus));

                            fetchCusData(
                                refreshPhone.phone_number,
                                refreshPhone.phone_ring.phone_line,
                                list,
                            );
                        }
                    } else {
                        setRing({ ...refreshPhone.phone_ring, is_ringing: false });
                        setPhoneCus({});
                        list[refreshPhone.phone_ring.phone_line] = {};
                        setPhoneLineList([...list].filter((item) => item.phoneCus));
                    }
                }
            });
            socket.on('notification', (newNotice) => {
                setNotice(newNotice);
            });
            socket.on('disconnect', () => {
                setTopic('reconnection');
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
                if (!interval.current) {
                    interval.current = setInterval(() => {
                        socketInit();
                    }, 10000);
                }
            });
            socket.on('connect_error', () => {
                setTopic('connection_error');
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
                if (!interval.current) {
                    interval.current = setInterval(() => {
                        socketInit();
                    }, 10000);
                }
            });
        }
    }, [socket]);
    useEffect(() => {
        if (token) {
            socketInit();
        }

        // const reconnectInterval = setInterval(() => {
        //    console.warn(socket, new Date().toLocaleString());
        //    socketInit();
        // }, 60000); // 10分鐘重新連線

        // return () => {
        //    clearInterval(reconnectInterval); // 清除定時器以避免內存洩漏
        //    socket && socket.disconnect();
        // };
    }, []);
    const settingPhoneRing = (value, index) => {
        message.loading({ content: '設定中', key });
        const array = phoneLine;
        array.splice(index, 1, String(value));
        socket.emit('setUserPhoneLines', {
            accessPhoneLine: array,
        });
        localStorage.setItem('phoneLine', array);
        document.cookie = `phoneLine=${array} `;
        message.success({ content: '設定完成', key });
    };
    return {
        topic,
        phoneLine,
        SupplierPhoneLine,
        phoneRing,
        ring,
        phoneCus,
        phoneLineList,
        notice,
        settingPhoneRing,
    };
};
