import React, { Suspense, createContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import Main from './Main';
import SiderMenu from './siderMenu';
import Toolbar from './Toolbar';
import Loading from '../pages/Loading';
import { websocket } from '../components/websocket';
import ServicePlanFactory from '../ServicePlan/ServicePlanFactory';
import UseSuppiler from '../hooks/UseSuppiler';
import firebase from '../firebase';
import { fetchApi } from '../services/ApiService';
import { getReadMode } from '../services/NoticeApi';
import firebaseConfig from '../firebase/firebaseConfig';
export const AppContext = createContext();

const PublicLayout = () => {
    let supplier = UseSuppiler().supplier;
    const websocketData = websocket();
    const [servicePlan, setServicePlan] = useState();
    const [firebaseNotice, setFirebaseNotice] = useState();
    const [readMode, setReadMode] = useState();

    useEffect(() => {
        firebaseConfig(setFirebaseNotice);
        if (supplier) {
            setServicePlan(ServicePlanFactory(supplier.service_plan));
            firebase(setFirebaseNotice);
            fetchReadMode();
        }
    }, [supplier]);
    const fetchReadMode = async () => {
        const result = await fetchApi(getReadMode, {
            setting_page: 'enable_notification',
        });
        setReadMode(result.data[0].user_setting_content.read);
    };
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AppContext.Provider
                value={{ websocketData, firebaseNotice, readMode, setReadMode, servicePlan }}
            >
                <Toolbar />
                <Layout>
                    <SiderMenu />
                    {servicePlan ? (
                        <Suspense fallback={<Loading />}>
                            <Main />
                        </Suspense>
                    ) : (
                        <Loading />
                    )}
                </Layout>
            </AppContext.Provider>
        </Layout>
    );
};

export default PublicLayout;
