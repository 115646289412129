import { apiRequest } from './ApiService';

export const getNoticeList = (paramsData) =>
    apiRequest().get('/aw-api/notification/list', {
        params: { ...paramsData },
    });

export const patchAllNotice = () => apiRequest().patch('/aw-api/notification/read-all');

export const getReadMode = (paramsData, pathVars) =>
    apiRequest().get('/aw-api/user/setting/page-list', {
        params: { ...paramsData, ...pathVars },
    });
export const putReadMode = (body) => apiRequest().put(`/aw-api/user/setting`, { ...body });
