import { CheckCircleOutlined } from '@ant-design/icons';

const DetailButton = (editButton, setupData, data) => {
    const { setFinishModalOpen, setMoneyModalOpen } = setupData;
    const { orderVer, fetchData, orderStatus } = data;
    return fetchData.order_delivery_status === '已完成訂單' && orderStatus.status === '配送中'
        ? [
              editButton,
              { key: '取消訂單' },
              {
                  key: '確認完成訂單',
                  type: 'primary',
                  icon: <CheckCircleOutlined />,
                  onClick: () =>
                      orderVer
                          ? setFinishModalOpen({
                                status: true,
                                type: '訂單完成',
                                edit: false,
                            })
                          : setMoneyModalOpen(true),
                  note: 'popconfirmFinish',
              },
          ]
        : null;
};

export default DetailButton;
