import axios from 'axios';
import style from './Loading.module.scss';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
const Loading = () => {
    const token = localStorage.getItem('userId');
    const location = useLocation();
    const navigate = useNavigate();
    let path = location.state?.path;

    useEffect(() => {
        CheakAPI();
    }, []);
    const CheakAPI = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_KEY}/aw-api/basic/phone-records?&page=${0}&size=${2}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                },
            );
            if (res.status === 200) {
                if (path) {
                    setTimeout(navigate(path), 2500);
                } else {
                    setTimeout(navigate('/Home'), 2500);
                }
            } else {
                setTimeout(navigate('/login'), 2500);
            }
        } catch (error) {
            if (error.response.data.status === 404) {
                navigate('/404');
            } else {
                setTimeout(navigate('/login'), 2500);
            }
        }
    };
    return (
        <div className={style.wrapper}>
            <div className={style.circle}></div>
            <div className={style.circle}></div>
            <div className={style.circle}></div>
            <div className={style.shadow}></div>
            <div className={style.shadow}></div>
            <div className={style.shadow}></div>
            <span>Loading</span>
        </div>
    );
};
export default Loading;
