import styles from '../../../pages/Orders/Order.module.scss';
import { CarOutlined } from '@ant-design/icons';

const orderStepList = (stepList) => {
    let newStepList = [...stepList];
    newStepList.splice(3, 0, {
        title: '配送完成',
        icon: <CarOutlined className={styles.stepsIcon} />,
        description: '配送員已配送完成訂單',
        current: 3,
        tagColor: 'purple',
    });
    return newStepList;
};

const orderStepCurrent = (stepList, orderStatus, fetchData) => {
    let newStepList = [...stepList];
    newStepList.splice(3, 0, {
        title: '配送完成',
        icon: <CarOutlined className={styles.stepsIcon} />,
        description: '配送員已配送完成訂單',
        current: 3,
        tagColor: 'purple',
    });
    const stepStatus =
        orderStatus === '配送中' && fetchData?.order_delivery_status === '已完成訂單'
            ? '配送完成'
            : orderStatus;

    return newStepList.find((item) => item.title === stepStatus)?.current;
};

export { orderStepList, orderStepCurrent };
